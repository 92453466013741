import './MaxAddAndRemoveProduct.css';
import {PlusOutlined, MinusOutlined} from '@ant-design/icons';
import {Context} from '../../Store';
import React, {useContext, useEffect, useState} from 'react';
import {Button, Icon} from 'react-materialize';
import {ProductInterface} from '../../product/ProductInterface';

export function MaxAddAndRemoveProduct (props:{
  message:string,
  cart?: boolean,
  product:ProductInterface
}) {
  const [state, dispatch] = useContext(Context);
  const [inputValue, setInputValue] = useState('');
  const cart = state.merchantParams?.noClient ? (state.cart[state.merchantParams.noClient] ? state.cart[state.merchantParams.noClient] : state.cart[0]) : state.cart[0];

  useEffect(() => {
    setInputValue(String(cart[props.product.id] || 0));
  }, [props.product.id, cart]);

  const getProductById = (id:string) => {
    for (const product of state.products[state.merchantId]) {
      if(product.models) {
        for (const currentProduct of product.models) {
          if (currentProduct.id == id) {
            return currentProduct;
          }
        }
      }
      else {
        if (product.id == id) {
          return product;
        }
      }
    }
  }


  const addProductToCart = () => {
    const currentQuantity = Math.min(parseInt(inputValue, 10) + 1, props.product.quantity);
    setInputValue(String(currentQuantity));
    dispatch({
      type: 'ADD-CARD',
      payload: {
        [props.product.id]: currentQuantity
      }
    });
  };

  const removeProductFromCart = () => {
    const currentQuantity = Math.max(parseInt(inputValue, 10) - 1, 0);
    setInputValue(String(currentQuantity));
    dispatch({
      type: 'ADD-CARD',
      payload: {
        [props.product.id]: currentQuantity
      }
    });
  };
  const handleInputChange = (event) => {
    let value = parseInt(event.target.value, 10);
    value = isNaN(value) ? 0 : Math.max(0, Math.min(value, props.product.quantity));
    setInputValue(String(value));
    if (value > 0) {
      dispatch({
        type: 'ADD-CARD',
        payload: {
          [props.product.id]: value
        }
      });
    }
  };
  const handleInputBlur = () => {
    const value = parseInt(inputValue, 10);
    if (value === 0) {
     setTimeout(()=>{
       dispatch({
         type: 'ADD-CARD',
         payload: {
           [props.product.id]: 0
         }
       });
      }, 2000)
    }
  };
  return (
    !cart?
    <div id={`Cashier-item-adding-${props.product.id}`} className={'Max-header-cart-modal-product-addg'}>
      <Button
        floating
        waves={'light'}
        className={'max-cart-add-but'}
        disabled={!cart[props.product.id]}
        onClick={removeProductFromCart}
        icon={<MinusOutlined className={'max-cart-add-but'}/>}
      />

      <p className={'Max-cart-quantity'}>
        {
          props.message == "" ?
            cart[props.product.id]
            :
            props.message}
      </p>

      <Button
        floating
        waves={'light'}
        className={'max-cart-add-but'}
        onClick={() => addProductToCart(props.product.id)}
        icon={<PlusOutlined    className={'max-cart-add-but'}/>}
        disabled={cart[props.product.id] == props.product.quantity || props.product.id == state.ruleParams.idProductShipping}
      />
    </div>
      :
      <div className={'Max-cart-control-cont2'}>
      <div className={'Max-cart-control-cont'}>
        <MinusOutlined onClick={removeProductFromCart} className={'Max-cart-control-icon'} />
          <input
            type='number'
            className='Max-cart-control-text'
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            min="0"
          />
        <PlusOutlined  onClick={() => {
          props.product.quantity>cart[props.product.id]&&
          addProductToCart();
        }} className={'Max-cart-control-icon'}/>
      </div>
        {!props.cart?<p className={'Max-cart-control-text2'}> {
          props.message}</p>:<></>}
        </div>
  )
}
