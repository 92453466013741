import './NewCart.css';
import {Context} from '../../Store';
import {Detail} from '../stock/Detail';
import {Button} from 'react-materialize';
import {LoyaltyPoints} from './LoyaltyPoints';
import {UseForm} from '../../../services/Form';
import * as HttpStatus from 'http-status-codes';
import {Utils} from '../../../services/Utils.class';
import {FormattedMessage, useIntl} from 'react-intl';
import {ProductInterface} from '../../product/ProductInterface';
import {HttpRequest} from '../../../services/HttpRequest.class';
import {BillingInformation} from '../billing/BillingInformation';
import React, {useContext, useState, createRef, useEffect} from 'react';
import {SwipeActionRef} from 'antd-mobile-v5/es/components/swipe-action';
import {Divider, Radio, Drawer, Empty, Spin, Tooltip, Switch} from 'antd';
import {FACValidators} from '../../../integrations/processors/fac/FACValidators';
import {ToTopOutlined, DownCircleOutlined, LoadingOutlined, TagsFilled} from '@ant-design/icons';

export function NewCart (props: {
  priceWithPoints:any,
  orderId?:string,
  editing?: boolean,
  pickupValues:any,
  addressValues:any,
  SubmitPickup: Function,
  selectedPickup: any,
  SubmitAddress: Function,
  paymentMethods:any,
  setDiscountPercent: Function,
  discountPercent: number,
  getOrder?: Function,
  loadingCart: boolean,
  setLoading: Function,
  setCurrent?: Function,
  setValidStock: Function,
  setLoadingCart: Function,
  setPriceWithPoints:Function,
}) {
  const intl = useIntl();
  const merchant = Utils.getMerchantByUrl();
  const [state, dispatch] = useContext(Context);
  const [discount, setDiscount] = useState(0);
  const [visible, setVisible] = useState(false);
  const [shippingProd, setShippingProd] = useState({});
  const [showCart, setShowCart] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [confirmOrder, setConfirmOrder] = useState(true);
  const [shippingCost, setShippingCost] = useState(true);
  const [suggestedOrder, setSuggestedOrder] = useState(true);
  const [showPopConfirm, setShowPopConfirm] = useState(false);
  const [orderProductPrice, setOrderProductPrice] = useState({})

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [idProductShipping, setIdProductShipping] = useState('nhlv2g');
  const [idVariableProduct, setIdVariableProduct] = useState('');
  const [minimumOrderAmount, setMinimumOrderAmount] = useState(0);
  const [validProdShipping, setValidProdShipping] = useState(false);
  const [hidePaymentMethods, setHidePaymentMethods] = useState(false);
  const [showShipmentAction, setShowShipmentAction] = useState(false);
  const [validateMinimumAmount, setValidateMinimumAmount] = useState(false);
  const [billingInformationEnabled, setBillingInformationEnabled] = useState(false);
  const [buttonCartText, setButtonCartText] = useState(intl.formatMessage({id: 'CASHIER_CART__MAKE_ORDER_ESTATE'}));
  const [shoppingListText, setShoppingListText] = useState(intl.formatMessage({id: 'CASHIER_CART__LABEL_CART_TITLE'}));

  const cart = state.merchantParams?.noClient ? (state.cart[ state.merchantParams.noClient ] ? state.cart[ state.merchantParams.noClient ] : state.cart[ 0 ]) : state.cart[ 0 ];

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const isShowCart = urlParams.get('showCart');

  useEffect(() => {
    getMerchantRules().then();
    getShippingProduct().then();
  }, []);

  useEffect(() => {
    if (isShowCart) {
      setShowCart(Boolean(parseInt(isShowCart)));
    }
  }, [isShowCart]);

  useEffect(() => {
    if (props.paymentMethods.length === 1) {
      setPaymentMethod(props.paymentMethods[ 0 ].name);
    }
  }, [props.paymentMethods]);

  useEffect(() => {
    if (state.cart[ 0 ].total == 0 || null) {
      updateCart().then();
    }
  }, [state.cart[ 0 ].total]);

  const getShippingProduct = async () => {
    const requestShipping = new HttpRequest(`https://${merchant}.${process.env.REACT_APP_APP_DOMAIN}` || '');
    const responseShipping = await requestShipping.get(`product/nhlv2g`);

    if (responseShipping.status === HttpStatus.OK) {
      setShippingProd(responseShipping.data);
    }
  };

  const addProductToCart = (id: string) => {
    const product = getProductById(id);
    if (Object.keys(product).length) {
      const quantity = [product.id] ? cart[ product.id ] + 1 : 1;

      dispatch({
        type: 'ADD-CARD',
        payload: {
          [ id ]: quantity
        }
      });
    }
  };

  const removeProductToCart = (id: string) => {
    const product = getProductById(id);
    if (Object.keys(product).length) {
      const quantity = cart[ product.id ] - 1;
      dispatch({
        type: 'ADD-CARD',
        payload: {
          [ id ]: quantity
        }
      });
    }
  };

  const getMerchantRules = async () => {
    const request = new HttpRequest(`https://${merchant}.${process.env.REACT_APP_APP_DOMAIN}` || '');
    const rulesRequest = await request.get('rule');

    if (rulesRequest.status === HttpStatus.OK) {
      const dataResponse = rulesRequest.data;
      dataResponse.map((current: any) => {
        if (current.name === 'productShipping' && !props.selectedPickup) {
          setIdProductShipping(current.value.id);
          addShipping()
          setShowShipmentAction(true);
        }

        if (current.name === 'hidePaymentMethods') setHidePaymentMethods(true);
        if (current.name === 'buttonCartText') setButtonCartText(current.value.text);
        if (current.name === 'variableProduct') setIdVariableProduct(current.value.id);
        if (current.name === 'shoppingListText') setShoppingListText(current.value.text);
        if (current.name === 'billingInformationEnabled') setBillingInformationEnabled(true);
        if (current.name === 'minimumOrderAmount') setMinimumOrderAmount(current.value.amount);
      });
    }
  };

  useEffect(() => {
    if (props.selectedPickup && cart[ idProductShipping ]) {
      removeShipping()
    } else {
      addShipping()
    }
  }, [props.selectedPickup, idProductShipping])

  const getIvaPercent = (product: any) => {
    return (getUnitaryIVA(product) / product.price) * 100
  }

  const setMerchantParams = (params: object) => {
    dispatch({
      type: 'ADD-MERCHANT-PARAMS',
      payload: params
    });
  };

  useEffect(() => {
    if (state.merchantParams.billingInformation) {
      handleChangeSwitch(true)
    }
  }, [state.merchantParams])

  const editOrder = async () => {
    if (props.selectedPickup) {
      if (!props.pickupValues?.pickup) {
        Utils.showNotificationMessage(
          'error',
          "",
          intl.formatMessage({id: 'MAX_NO_SHIPMENT'})
        );
        setShowCart(false)
        return false
      }
    } else if (!props.addressValues?.location) {
      Utils.showNotificationMessage(
        'error',
        "",
        intl.formatMessage({id: 'MAX_NO_SHIPMENT'})
      );
      setShowCart(false)
      return false
    }
    props.setLoading(true);

    if (checked) {
      setMerchantParams({
        ...state.merchantParams,
        billingInformation: true
      });
      console.log(state.merchantParams)
    }

    let data = {
      products: cart,
      paymentMethod: paymentMethod,
    };
    props.setCurrent && props.setCurrent(1);
    if (props.selectedPickup) {

      await props.SubmitPickup()
    } else {

      await props.SubmitAddress()
    }

    const request = new HttpRequest(`https://${merchant}.${process.env.REACT_APP_APP_DOMAIN}` || '');
    const processOrderRequest = await request.put(props.orderId || '', data);

    if (processOrderRequest.status === HttpStatus.CREATED) {
        const id = props.orderId
        const response = await request.post({
          orderId: id,
          products: getTotalItems().productsObject
        }, 'orderProductPrice');

        if (response.status !== HttpStatus.CREATED) {
          await Utils.sendEmail(JSON.stringify({
            response: response,
            request: JSON.stringify(request)
          }), `${process.env.REACT_APP_STAGE} Error registrando en orderProductPrice`, ['it@any2suite.com']);
        }
      props.setLoading(false);
      props.setCurrent && props.setCurrent(2);
      props.getOrder && props.getOrder();
    } else {
      props.setLoading(false);

      Utils.showNotificationMessage(
        'error',
        intl.formatMessage({id: 'CASHIER_PAY_ORDER__ERROR_NOTIFICATION_TITLE_GENERATING_ORDER'}),
        intl.formatMessage({id: 'CASHIER_PAY_ORDER__ERROR_NOTIFICATION_MESSAGE_GENERATING_ORDER'})
      );

      await Utils.sendEmail(JSON.stringify({
        response: processOrderRequest,
        request: JSON.stringify(request)
      }), `${process.env.REACT_APP_STAGE} Error generando la orden`, ['it@any2suite.com']);
    }
  };

  const getProductById = (id: string) => {
    for (const product of state.products[ state.merchantId ]) {
      if (product.models) {

        for (const currentProduct of product.models) {
          if (currentProduct.id == id) {
            return currentProduct;
          }
        }
      } else {
        if (product.id == id) {
          return product;
        }
      }
    }

    return {
      details: []
    };
  };

  const getTotalItems = () => {
    let total = 0;
    let iva = 0;
    let subtotal = 0;
    let shippingCostPrice = 0
    let temp = 0
    let productsObject = {}

    if (!discount) {
      Object.keys(cart).map((idProduct: string) => {
        if (idProduct != 'total') {
          let currentProduct = getProductById(idProduct)
          if (idProduct == idProductShipping && cart[ idProduct ]) {

            total += currentProduct.price * cart[ idProduct ]
            iva += getUnitaryIVA(currentProduct)* cart[ idProduct ]
            subtotal += (currentProduct.price - getUnitaryIVA(currentProduct) ) * cart[ idProduct ]
            shippingCostPrice = (currentProduct.price - getUnitaryIVA(currentProduct)) * cart[ idProduct ]
            productsObject = {...productsObject, [idProduct] :currentProduct.price}
           } else {
            total += (currentProduct.price + (getUnitaryIVA(currentProduct))) * cart[ idProduct ]
            iva +=  getUnitaryIVA(currentProduct) * cart[ idProduct ]
            subtotal += currentProduct.price * cart[ idProduct ]
            productsObject = {...productsObject, [idProduct] : ((currentProduct.price + (getUnitaryIVA(currentProduct))))}
          }
        }
      })
    }

    if (discount) {
      Object.keys(cart).map((idProduct: string) => {
        if (idProduct != 'total') {
          let currentProduct = getProductById(idProduct)
          if (idProduct == idProductShipping && cart[ idProduct ]) {
            temp += (currentProduct.price - getUnitaryIVA(currentProduct) ) * cart[ idProduct ]
          } else {
            temp += currentProduct.price * cart[ idProduct ]
          }
        }
      })
      let percentDiscount = Number(getPercentDiscountToApply(temp).toFixed(2));
      if(props.discountPercent!==percentDiscount)props.setDiscountPercent(percentDiscount)

      Object.keys(cart).map((idProduct: string) => {
        if (idProduct != 'total') {
          let currentSubTotal = 0
          let currentDiscount = 0
          let currentIvaPercent = 0
          let currentProduct = getProductById(idProduct)
          if (idProduct == idProductShipping && cart[ idProduct ]) {
            currentIvaPercent = (getUnitaryIVA(currentProduct) * 100)  / currentProduct.price
            currentSubTotal = (currentProduct.price - getUnitaryIVA(currentProduct)) * cart[ idProduct ]
            currentDiscount = parseFloat((currentSubTotal * percentDiscount / 100).toFixed(2))
            shippingCostPrice = (currentProduct.price - getUnitaryIVA(currentProduct)) * cart[ idProduct ]

            subtotal += currentSubTotal
            total +=  parseFloat(((currentSubTotal - currentDiscount) * (1 + (currentIvaPercent/100))).toFixed(2))
            iva += (currentIvaPercent/100)*(currentSubTotal - currentDiscount)
            productsObject = {...productsObject, [idProduct]: parseFloat(((currentSubTotal - currentDiscount) * (1 + (currentIvaPercent/100))).toFixed(2))}
          } else {
            currentIvaPercent = (getUnitaryIVA(currentProduct) * 100)  / (currentProduct.price + getUnitaryIVA(currentProduct))
            currentSubTotal = currentProduct.price
            currentDiscount =  (currentSubTotal * percentDiscount / 100)

            subtotal += currentSubTotal * cart[ idProduct ]
            total += parseFloat(((currentSubTotal - currentDiscount) *  (1 + (currentIvaPercent/100)) * cart[ idProduct ]).toFixed(2))
            iva += ((currentSubTotal - currentDiscount) * (currentIvaPercent/100)) * cart[ idProduct ]
            productsObject = {...productsObject, [idProduct] :parseFloat(((currentSubTotal - currentDiscount) *  (1 + (currentIvaPercent/100))).toFixed(2))}
          }
        }

      })

      if(total!==props.priceWithPoints)props.setPriceWithPoints(total)

    }

  return {subtotal, iva, total, shippingCostPrice, productsObject};
}





  const getPercentDiscountToApply= (subtotal:number) => {
    return Math.floor(discount * 100) / subtotal;
  }

  const applyEqualDiscount = (cart:[], discount:number) => {
    let discountAmount = 0;
    let ivaWithDiscount = 0;
    let totalWithDiscount = 0;

    const calculateProductDiscount = (product:any) => {
      const productPrice = product.price;
      const productDiscount = productPrice * (discount / 100);
      const productIva = (productPrice - productDiscount) * ((Math.floor(product.sku.taxAsInt * 100 / productPrice) / 100) / 100);

      discountAmount += productDiscount;
      ivaWithDiscount += productIva;
      totalWithDiscount += productPrice + productIva;
    };

    for (const productId in cart) {
      if (productId !== 'total' && cart.hasOwnProperty(productId)) {
        const product = getProductById(productId);
        calculateProductDiscount(product);
      }
    }

    return [ivaWithDiscount];
  };

  const getTotalPriceProduct = (product:any) => {
    let total = cart[product.id] * product.price;
    return total;
  };

  const getTotalIVA = (product:any) => {
    return cart[product.id] * Math.floor(product?.sku?.taxAsInt / 100);
  };

  const isValidPromo = (product:any) => {
    if (product.promo?.type === 'regular' && !product.promoPrice && cart[product.id] >= 1) return true;
    if (product.promo?.type === 'quantity' && !product.promoPrice && cart[product.id] >= product.promo?.value) return true;
  };

  const getQuantityItems = () => {
    let total = 0;
    for (const current in cart) {
      if (current != 'total') {
        if (cart.hasOwnProperty(current)) {
          const product = getProductById(current);
          total += cart[product.id];
        }
      }
    }

    return total;
  };



  useEffect(() => {
    if (confirmLoading) {
      setTimeout(() => {
        updateCart().then();
        setConfirmLoading(false);
        setShowPopConfirm(false);


        if (state.merchantParams?.noClient) {
          setConfirmOrder(true);
          setShippingCost(false);
          setValidateMinimumAmount(false);
          validateShippingProduct(idProductShipping);
        }
      }, 2000);
    }
  }, [confirmLoading]);

  const updateCart = async () => {
    dispatch({
      type: 'CLEAN-CART'
    });
  };

  const quantity = getQuantityItems();
  const article = quantity == 1 ? intl.formatMessage({id: 'CASHIER_CART__LABEL_PRODUCT'}) : intl.formatMessage({id: 'CASHIER_CART__LABEL_PRODUCTS'});
  const quantityItems = `${quantity} ${article}`;

  const payBTN = (
    <Button
      onClick={ editOrder }
      style={{margin:0}}
      className={'editing-cart-order-confirm-btn'}
      disabled={!cart.total || !paymentMethod}
    >{buttonCartText}</Button>
  );

  const onChangePaymentMethod = (e:any) => {
    setPaymentMethod(e.target.value);
  };

  useEffect(() => {
    if (state.merchantParams?.noClient && validateMinimumAmount) {
      const isValidProdShipping = cart[idProductShipping] && cart['total'] == 1;
      setValidProdShipping(isValidProdShipping);

      const orderAmount = getTotalItems().total;

      if (orderAmount < minimumOrderAmount && !shippingCost || isValidProdShipping) {
        setConfirmOrder(false);
      } else {
        setConfirmOrder(true);
      }
      setValidateMinimumAmount(false);
    }
  }, [validateMinimumAmount]);

  const isValidOrderWithoutShipping = (shipmentProduct:string) => {
    const orderAmount = getTotalItems().total;
    const product = getProductById(shipmentProduct);
    const shippingAmount = cart[product.id] * product.price;
    return (orderAmount - shippingAmount) >= minimumOrderAmount;
  };

  const addShipping = () => {
    if(idProductShipping) {
      dispatch({
        type: 'ADD-CARD',
        payload: {
          [ idProductShipping ]: 1
        }
      });

      setVisible(false);
      setShippingCost(true);
      setConfirmOrder(true);
      setValidateMinimumAmount(false);
    }
  };

  const removeShipping = () => {
    dispatch({
      type: 'ADD-CARD',
      payload: {
        [idProductShipping]: 0
      }
    });
  };

  const validateShippingProduct = (shipmentProduct:string) => {
    if (!cart[shipmentProduct] && shippingCost) {
      setShippingCost(false);
      setValidateMinimumAmount(false);
    }

    if (cart[shipmentProduct] && !shippingCost) {
      setShippingCost(true);
    }

    if (cart[shipmentProduct] && isValidOrderWithoutShipping(shipmentProduct)) {
      removeShipping();
    }
  };

  const [checked, setChecked] = useState(false);

  const [showLoyalty, setShowLoyalty] = useState(false);

  const getUnitaryIVA = (product:any) => {
    return Math.floor(product?.sku?.taxAsInt / 100);
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit
  } = UseForm(FACValidators, () => {
  });
  const handleChangeSwitch = (checked:boolean) => {
    setChecked(checked);
  };

  const handleChangeLoyalty = () => {
    if (!showLoyalty) {
      setShowLoyalty(true);
    } else {
      setShowLoyalty(false);
    }
  };

  const drawerComponent = visible ? (
    <Drawer
      closable={true}
      visible={visible}
      placement={'bottom'}
      getContainer={false}
      style={{position: 'absolute'}}
      onClose={() => setVisible(false)}
    >
      <div className={'editing-paymentoptions-container'}>
        <p className={'editing-paymentoptions-text'}>Métodos de pago:</p>
        <div className={'Editing-cart-payment-methods'}>
          {!confirmOrder && <div className={'Cashier-cart-order-confirm'}>
            {!validProdShipping ?
              <div className={'Cashier-cart-order-confirm-message'}><FormattedMessage id={'CASHIER_CART__ERROR_MINIMUM_ORDER_AMOUNT_PREV'}/>
                ({state.currentMerchant.isoCurrency.symbol + Utils.formatMoney(minimumOrderAmount, state.ruleParams?.urlStockEnable ? 0 : 2)})<FormattedMessage
                  id={'CASHIER_CART__ERROR_MINIMUM_ORDER_AMOUNT_POST'}/></div> :
              <div className={'Cashier-cart-order-confirm-message'}><FormattedMessage id={'CASHIER_CART__ERROR_EMPTY_CART'}/></div>
            }
            <div>
              <Button
                onClick={() => {
                  setVisible(false);
                  setConfirmOrder(true);
                  setValidateMinimumAmount(false);
                }}
                className={'editing-cart-order-confirm-btn'}
              >
                <FormattedMessage id={'CASHIER_CART__BUTTON_ADD_PRODUCTS'}/>
              </Button>

              {!validProdShipping && showShipmentAction ?
                <Button
                  onClick={addShipping}
                  className={'editing-cart-order-confirm-btn'}
                >
                  <FormattedMessage id={'CASHIER_CART__BUTTON_SHIPPING_AMOUNT'}/>
                </Button> :
                ''
              }
            </div>
          </div>}

          {confirmOrder && <Radio.Group className={'Cashier-radio-payment-methods'}>
            <Radio.Group
              defaultValue={paymentMethod}
              onChange={onChangePaymentMethod}
            >
              {props.paymentMethods.map((current:any) =>
                <Radio.Button
                  key={current.name}
                  value={current.name}
                >{current.name}</Radio.Button>
              )}
            </Radio.Group>
          </Radio.Group>}
        </div>
        {confirmOrder && payBTN}
      </div>
    </Drawer>
  ) : '';

  const drawProductsList = (productId:string) => {
    if (state.merchantParams?.noClient) {
      if (productId === idProductShipping) {
        validateShippingProduct(idProductShipping);
      }

      if (visible && !validateMinimumAmount) {
        if (!shippingCost) {
          setValidateMinimumAmount(true);
        } else {
          if (cart['total'] == 1) {
            setValidateMinimumAmount(true);
          }
        }
      }
    }

    if (productId != 'total'&& productId != "nhlv2g" && cart[productId]) {
      const reference = createRef<SwipeActionRef>();
      const product: ProductInterface = getProductById(productId);
      let img = (
        <div
          id={`${product.imgId}`}
          className={'New-cart-item-productImg'}
          style={{
            backgroundImage: `url(${process.env.REACT_APP_S3_URL}/${state.currentMerchant.username}/${product.imgId})`
          }}
        >
          <div className={'NewCart-Card-Flag'}><TagsFilled
            className={'NewCart-TagsFilled'}/>{Math.ceil(product.promo?.percent)}%
          </div>
        <i
          id={`Cashier-item-img-${product.id}`}
          className={'medium material-icons Customer-merchant-icon'}
        >loyalty</i></div>
      );

      if (product.imgId !== '') {
        img = (
          <div
            id={`${product.imgId}`}
            className={'New-cart-item-productImg'}
            style={{
              backgroundImage: `url(${process.env.REACT_APP_S3_URL}/${state.currentMerchant.username}/${product.imgId})`
            }}
          >
            {product.promo?.percent?<div className={'NewCart-Card-Flag'}><TagsFilled
              className={'NewCart-TagsFilled'}/>{Math.ceil(product.promo?.percent)}%
            </div>:<></>}
          </div>
        );
      }

      let mark = <></>;
      let measure = <></>;
      let sideDish = <></>;
      let beverage = <></>;
      product.details?.map((currentDetail:any) => {
        if (currentDetail.type === 'medida') {
          measure = <span className={'Cashier-cart-item-measure'}>{currentDetail.value}</span>;
        }
        if (currentDetail.type === 'marca') {
          mark = <span className={'Cashier-cart-item-measure'}>{currentDetail.value}</span>;
        }
        if (currentDetail.name === 'sideDish') {
          sideDish = <span className={'Cashier-cart-item-measure'}>{currentDetail.product.name}</span>;
        }
        if (currentDetail.name === 'beverage') {
          beverage = <span className={'Cashier-cart-item-measure'}>{currentDetail.product.name}</span>;
        }
      });
      let price = ((product.price+getUnitaryIVA(product))*cart[productId])
      return (
        <div key={productId} className={'new-header-cart-modal-product'}>
          <div className={'new-header-cart-modal-product-info'}>
            <div>
              {img}
            </div>
            <div className={'new-data-product-cart'}>
              <span className={'new-cart-item-name'}>{product.name}</span>
              {measure}
              {mark}
              {sideDish}
              {beverage}
              <div className={'new-header-cart-modal-product-adding'}>
                <div className={'new-cart-add-button'} onClick={()=>removeProductToCart(productId)}>-</div>
                <div className={'new-cart-quantity'}>{cart[productId]}</div>
                <div className={'new-cart-add-button'} onClick={()=>addProductToCart(productId)}>+</div>
              </div>
              <div className={'new-item-cart-draw-details'}>
                {product.details.map((current:any, index: number) => {
                  return <Detail key={index} type={current.type} value={current.value} selected={false}/>;
                })}
              </div>
            </div>
          </div>
          <div className={'new-cart-item-price'}>
            {state.currentMerchant.isoCurrency.symbol + Utils.formatMoney(price)}
          </div>
        </div>
      );
    }
  };

  const getSuggestedOrder = async (noClient:string) => {
    const request = new HttpRequest(`${process.env.REACT_APP_API_HOST}/ciisaCustomer/suggestedOrder/${noClient}` || '');
    const suggestedOrderRequest = await request.post();

    if (suggestedOrderRequest.status === HttpStatus.CREATED) {
      if (Object.keys(suggestedOrderRequest.data).length) {
        dispatch({
          type: 'UPDATE-CART',
          payload: suggestedOrderRequest.data
        });
      } else {
        Utils.showNotificationMessage(
          'info',
          intl.formatMessage({id: 'CASHIER_CART__INFO_NOTIFICATION_TITLE_NO_SUGGESTED_ORDER'}),
          intl.formatMessage({id: 'CASHIER_CART__INFO_NOTIFICATION_MESSAGE_NO_SUGGESTED_ORDER'})
        );
      }
    } else {
      Utils.showNotificationMessage(
        'error',
        intl.formatMessage({id: 'CASHIER_CART__ERROR_NOTIFICATION_TITLE_FETCHING_SUGGESTED_ORDER'}),
        intl.formatMessage({id: 'CASHIER_CART__ERROR_NOTIFICATION_MESSAGE_FETCHING_SUGGESTED_ORDER'})
      );
    }

    props.setLoadingCart(false);
    setSuggestedOrder(false);
  };



  return (
    <>
      {
        !showCart?
          <i onClick={()=>setShowCart(!showCart)} style={(cart.total ? props.editing ? {color: '#FFF'} : {color: `${state.currentMerchant.styles?.color3}`} : {color: `${state.currentMerchant.styles?.color3}`})}
             className={cart.total ? 'medium material-icons new-header-cart-icon new-header-cart-icon__full' :
               'medium material-icons new-header-cart-icon'}
          >shopping_cart</i>
          :
          <>  <i onClick={()=>setShowCart(!showCart)} style={(cart.total ? props.editing ? {color: '#FFF'} : {color: `${state.currentMerchant.styles?.color3}`} : {color: `${state.currentMerchant.styles?.color3}`})}
                 className={cart.total ? 'medium material-icons new-header-cart-icon new-header-cart-icon__full' :
                   'medium material-icons new-header-cart-icon'}
          >shopping_cart</i>
            <div className={'New-Cart-Modal-Opacity'} >
              <div className={'new-header-cart-modal'}>
                <div className={'new-header-cart-modal-title'}>
                  <p className={'new-cart-title'}>{shoppingListText}</p>
                  <Button onClick={()=>setShowCart(false)} flat modal={'close'} className={'new-header-cart-modal-close-btn'}>X</Button>
                </div>
                <div className={'New-cart-products-container'}>
                  {
                    (cart.total===1&&cart[idProductShipping])?
                      <div className={'new-item-list-empty'}>
                        {
                          state.merchantParams?.suggestedOrder && suggestedOrder ?
                            <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}>
                              <FormattedMessage id={'CASHIER_PAY_ORDER__TO_LOADING'}/>
                            </Spin> :
                            <Empty
                              description={<span><FormattedMessage id={'CASHIER_STOCK_CASHIER__LABEL_EMPTY_PRODUCTS'}/></span>}
                            />
                        }
                      </div>
                      :
                    cart.total ? Object.keys(cart).map(drawProductsList) :
                      <div className={'new-item-list-empty'}>
                        {
                          state.merchantParams?.suggestedOrder && suggestedOrder ?
                            <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}>
                              <FormattedMessage id={'CASHIER_PAY_ORDER__TO_LOADING'}/>
                            </Spin> :
                            <Empty
                              description={<span><FormattedMessage id={'CASHIER_STOCK_CASHIER__LABEL_EMPTY_PRODUCTS'}/></span>}
                            />
                        }
                      </div>
                  }
                </div>
                <div className={'New-cart-content-container'}>
                  {billingInformationEnabled ?
                    <div className={'New-Cart-switch-billing-information'}>
                      <p className={'new-cart-title'}>Factura electrónica</p>
                      <Switch size={'small'} className={'Cart-switch'} checked={checked} onChange={handleChangeSwitch}/>
                    </div>
                    : <></>
                  }
                  {billingInformationEnabled && checked ?
                    <BillingInformation values={values} merchantParams={state.merchantParams} errors={errors} handleSubmit={handleSubmit} handleChange={handleChange}/>
                    :
                    <></>
                  }
                  {
                    <div className={'New-Cart-switch-billing-information'}>
                      <p className={'new-cart-title'}>Aprovechá tu acumulación</p>
                      <DownCircleOutlined className={'loyalty-right-icon'} onClick={() => {handleChangeLoyalty()}}/>
                    </div>
                  }

                  {showLoyalty ?
                    <LoyaltyPoints setDiscount={setDiscount} totalPrice={getTotalItems().total} ivaPrice={getTotalItems().iva} subTotalPrice={getTotalItems().subtotal}/>
                    :
                    <></>
                  }

                  <div className={'new-header-cart-modal-product-footer'}>
                    <Divider className={'new-divider-cart'}/>
                    <div className={'new-cart-total'}>
                      <div className={'new-cart-item-name-total'}>
                        <div>
                          <span className={'new-cart-item-price-total'}>{Object.keys(cart).filter(e=>e!=='total'&&e!=="nhlv2g").length +' ' + article  }</span>
                        </div>
                        <div className={'new-total-price'}>
                      <span style={state.ruleParams.urlStockEnable && shippingCost ? {display: 'initial'} : {display: 'none'}} className={'new-cart-item-price-total'}>
                        ENVIO {state.currentMerchant.isoCurrency.symbol + Utils.formatMoney(getTotalItems().shippingCostPrice, state.ruleParams?.urlStockEnable ? 0 : 2)}
                      </span>
                          <span style={state.ruleParams.urlStockEnable ? {display: 'initial'} : {display: 'none'}} className={'new-cart-item-price-total'}>
                        SUBTOTAL {state.currentMerchant.isoCurrency.symbol + Utils.formatMoney(getTotalItems().subtotal, state.ruleParams?.urlStockEnable ? 0 : 2)}
                      </span>
                          <span style={state.ruleParams.urlStockEnable && discount > 0 ? {display: 'initial'} : {display: 'none'}} className={'new-cart-item-price-total'}>
                        DESCUENTO {state.currentMerchant.isoCurrency.symbol + Utils.formatMoney(discount, state.ruleParams?.urlStockEnable ? 0 : 2)}
                      </span>
                          <span style={state.ruleParams.urlStockEnable ? {display: 'initial'} : {display: 'none'}} className={'new-cart-item-price-total'}>
                        IVA {state.currentMerchant.isoCurrency.symbol + Utils.formatMoney(getTotalItems().iva, state.ruleParams?.urlStockEnable ? 0 : 2)}
                      </span>
                          <span className={'new-cart-item-price-total'}>
                        TOTAL {state.currentMerchant.isoCurrency.symbol + Utils.formatMoney(getTotalItems().total, state.ruleParams?.urlStockEnable ? 0 : 2)}
                      </span>
                        </div>
                      </div>

                      {
                        props.paymentMethods.legth>2?
                        !hidePaymentMethods &&
                        <div className={'new-cart-content-price-total'}>
                    <span className={'new-cart-item-price-payment-method'}><FormattedMessage
                      id={'CC__METHOD_PAYMENT'}/>
                    </span>
                          <ToTopOutlined onClick={() => {
                            setVisible(true);
                            setValidateMinimumAmount(true);
                          }} className={'new-cart-icon-payment-method'}/>
                        </div>
                          :
                          <div className={'new-cart-method-Cont'}><p className={'new-cart-method-T'}>- {props.paymentMethods[0].name}</p>{confirmOrder && payBTN}</div>

                      }
                    </div>
                    <Divider className={'new-divider-cart'}/>
                    {drawerComponent}
                  </div>
                </div>
              </div>
            </div>
          </>
      }
    </>
  )}